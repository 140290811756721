.nav-bar {
  display: flex;

  justify-content: space-between;
  align-items: center;

  width: 100vw;
  height: 2.5em;

  padding: 0 1em;

  background-color: #cccccc;

  .nav-items {
    display: flex;
    flex-direction: row;
    font-size: 1em; }

  .link {
    color: black;
    text-decoration: none;

    margin: 0 1em;

    &:hover {
      opacity: 0.7; } }

  .title-group {
    display: flex;
    align-items: center; }

  .title {
    font-size: 2em;

    margin: 0;
    margin-right: 0.25em; }

  .drop-down-group {
    position: relative;
    margin: 0 1em;

    margin: 0 1em;

    cursor: pointer;

    .drop-down-triangle {
      font-size: 0.5em; }

    .drop-down {
      left: 0;
      position: absolute;
      width: auto;
      display: none;

      background-color: #cccccc;

      box-shadow: 2px 9px 8px rgba(0,0,0,0.5);

      z-index: 9999;

      &.is-active {
        display: flex;
        flex-direction: column; }

      .drop-down-item {
        width: 100%;
        color: black;
        text-decoration: none;

        padding: 0.5em 1em;

        white-space: nowrap;

        &:hover {
          background-color: #959595; } } } } }


