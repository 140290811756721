.player-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: center;

  height: 100%;

  // z-index: 25

  &.credits-active {}

  .fullscreen-button {
    font-size: 1.5em; }

  .fullscreen-button, .play-pause {
    cursor: pointer;
    color: white;

    width: auto;
    max-height: 2em;

    border: 1px solid white;

    padding: 0.3em 0.5em;
    margin: auto 0;

    &:hover {
      opacity: 0.7; } }

  .center-controls {
    display: flex;

    width: 100%;
    max-height: 2em !important;

    margin: auto;
    padding: 0 2em;

    color: white;

    justify-content: space-between;

    .side-controls {
      display: flex;
      justify-content: space-between;

      width: 50%;
      padding: 0 1em;

      .prev-button, .next-button {
        cursor: pointer;

        border: 1px solid white;

        padding: 0.3em 0.5em;
        width: auto;

        &:hover {
          opacity: 0.7; } } } }

  .credits-button,
  .close-credits,
  .comment-button, {
    height: auto;
    width: auto;
    max-height: 2em !important;
    max-width: 4em !important;
    padding: 0.3em 0.5em;

    color: white;

    border: 1px solid white;

    cursor: pointer;


    &:hover {
      opacity: 0.7; } }

  .comment-button {
    max-width: 15em !important;
    margin-bottom: 1em; }

  .close-credits {
    position: absolute;
    top: 6vw;

    max-width: 8em !important;

    margin: 0 auto; }


  .thumbs {
    height: auto;
    max-height: 4em !important;

    margin-bottom: 2em;

    .thumb {
      width: 3em;
      height: auto;
      margin: 0 1em;

      border: 1px solid white;
      padding: 0.4em 0.5em 0 0.5em;

      path {
        fill: white; }

      cursor: pointer;

      &:hover {
        opacity: 0.7; } }

    .thumbsdown {
      transform: rotate(180deg); }

    &.like {
      .thumbsup {
        border: none;
        cursor: inherit;

        &:hover {
          opacity: 1; } }

      .thumbsdown {
        display: none; } }

    &.dislike {
      .thumbsdown {
        border: none;
        cursor: inherit;

        &:hover {
          opacity: 1; } }

      .thumbsup {
        display: none; } } }

  .credits {
    width: 60%;
    max-width: 60% !important;

    margin: 12vw 1em 1em 1em;

    color: white;

    overflow: scroll; }

  .credits-text {
    font-size: 2em;
    text-align: left;

    br {
      margin-top: 0.5em; } } }

.comment-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;

  padding: 4em;

  div {
    max-height: 2em !important;
    margin: 2em 0; }

  textarea {
    width: 40%;
    height: 40%;

    min-height: 4em;
    max-height: 10em;

    &:focus {
      outline: none; } } }

.comment-submit, .close-comment {
    height: auto;
    width: auto;
    max-height: 2em !important;
    max-width: 4em !important;
    padding: 0.3em 0.5em;
    margin: 1em 0;

    color: white;
    border: 1px solid white;

    cursor: pointer;


    &:hover {
      opacity: 0.7; } }
