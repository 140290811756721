*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  background: #959595;

  font-family: tekton-pro, sans-serif;
  font-style: normal;
  font-weight: 900; }

.home {
  height: calc(100vh - 2.5em);
  text-align: center;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  align-items: center;

  overflow-y: hidden; }

.home-link {
  color: #61dafb; }

.loader {
  width: 100vw;
  height: calc(100vh - 2.5em);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 8; }


.video-players {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 100vw;
  height: calc(100vw * 9 / 16);
  z-index: 9;

  .fade-in {
    &.is-active {
      z-index: 999;
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;

      width: 100%;
      height: 100%;

      background-color: #585858;

      -webkit-animation: FADE-IN-OUT 4s ease;
      -moz-animation: FADE-IN-OUT 4s ease;
      -o-animation: FADE-IN-OUT 4s ease;
      animation: FADE-IN-OUT 4s ease; } }

  .hour {
    position: absolute;
    top: 3.125vw;
    left: 3.125vw;
    font-size: 10vw;

    color: black;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;

    font-family: Nunito;
    font-weight: 900;
    z-index: 21;

    &.unsynced {
      font-size: 5vw; } } }

@media (min-width: 177.778vh) {
  .video-players {
    height: 100vh;
    width: calc(93vh * 16 / 9);

    .hour {
      font-size: calc(10vh * 16 / 9); } } }

.video-player-container {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  z-index: 1;


  width: 100%;
  height: 100%;

  transition: opacity 2s ease;

  div, iframe {
    height: 100%;
    width: 100%; }

  &.full-screen-mode {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div, iframe {
      height: 100%;
      width: 100%;
      max-height: 100vh; } }

  &.is-active {
    opacity: 1;

    z-index: 20; } }

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit; }


@-webkit-keyframes FADE-IN-OUT {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes FADE-IN-OUT {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes FADE-IN-OUT {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes FADE-IN-OUT {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }
