.top-row {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  padding: 0.5em 1em;

  .unsynced-title {
    max-width: 4em;

    text-align: center; } }

.reset-button {
  display: flex;
  align-items: center;

  padding: 0.2em 0.5em;
  margin: 0 2em;

  text-decoration: none;
  color: #000000;
  background-color: #FFFFFF;
  border: 2px solid #000000;

  cursor: pointer;

  &:hover {
    opacity: 0.7; } }

.playlist {
  display: flex;

  align-items: center;

  width: 100%;

  .play-button {
    padding: 0.2em 0.5em;
    margin: 0 1em;

    text-decoration: none;
    color: #000000;
    background-color: #FFFFFF;
    border: 2px solid #000000;

    &:hover {
      opacity: 0.7; } }

  .title {
    width: 30%;
    min-width: 30%;

    font-size: 1.5em; }

  .description {
    width: 40%;
    padding-left: 1em; }

  .unsynced-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 1.5em;
    width: 1.5em;

    margin-left: auto;
    margin-right: 2.3em;

    background-color: #FFFFFF;
    border: 2px solid #000000;

    opacity: 0.5;

    cursor: not-allowed;
    user-select: none;

    &.clickable {
      opacity: 1;
      cursor: pointer; } }

  .unsynced-descriptor {
    font-size: 0.66em; } }

.note {
  width: 100%;

  text-align: right;

  padding: 2em; }
