.video-player-group {
  position: relative; }

.player-overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;

  background: rgba(0, 0, 0, 0.2);

  opacity: 0;

  transition: opacity 0.2s ease;

  display: none;

  &.credits-active {
    background: rgba(0, 0, 0, 0.5); }

  &.is-active {
    opacity: 1;
    z-index: 25; }

  &.is-displayed {
    display: block; } }
