.video-check-group {
  padding: 2em;

  display: flex;
  flex-direction: column;
  align-items: center; }

.video-check-button {
  color: white;
  border: 1px solid white;

  margin: 2em 0;
  padding: 0.2em 0.5em;

  &:hover {
    opacity: 0.7; } }

.progress-bar {
  position: relative;
  width: 20em;
  height: 2em;

  border: 2px solid black; }

.progress-bar-fill {
  position: absolute;
  height: calc(2em - 4px);

  background-color: green; }

.row {
  display: flex; }

.column {
  border: 1px solid black;
  width: 50%;
  padding: 1em 2em; }
